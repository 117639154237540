<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('Summary Of Midical Fees') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-select
            v-model.trim="doctorSelect"
            :items="doctorList"
            :label="$t('status')"
            outlined
            dense
            item-text="user_fullname"
            item-value="user_id"
            @change="addPayload(payload.start, payload.end)"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        item-key="number"
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.number`]="{ index, item }">
          <span v-if="item.code">
            {{ index + 1 }}
          </span>
        </template>
        <template v-slot:[`item.code`]="{ item }">
          <router-link
            v-if="item.course_type == 'Check' || item.course_type == 'ตรวจ'"
            :to="{ name: 'ShowDetailCheck', params: { id: item.id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.code }}
          </router-link>
          <router-link 
            v-else-if="item.course_type == 'Service' || item.course_type == 'บริการ'"
            :to="{ name: 'showDetailService', params: { id: item.id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.code }}
          </router-link>
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            v-if="item.order_id"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span v-if="item.date != $t('total')">
            {{ item.date | sumdatetime }}
          </span>
          <span v-else class="font-weight-bold primary--text">
            {{ item.date }}
          </span>
        </template>
        <template v-slot:[`item.cost`]="{ item }">
          <span :class="item.date == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.cost }}
          </span>
        </template>
      </v-data-table>
      <v-data-table
        :headers="columns2"
        :items="dataTableList2"
        class="mt-4"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportDoctor, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate, sumdatetime } from '@/plugins/filters'
import doctorReport from '@/api/doctorReport'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'

export default {
  components: {
    Comeback,
    DateFilters,
    ConverseDate,
  },
  filters: {
    sumdatetime,
  },
  setup() {
    const doctorSelect = ref('')
    const doctorList = ref([])
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const dataTableList = ref([])
    const dataUserList = ref([])
    const dataShowList = ref([])
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('a_number'), value: 'code', width: 110 },
      { text: i18n.t('receipt_number'), value: 'order_id', width: 130 },
      { text: i18n.t('doctor'), value: 'user_fullname', width: 180 },
      { text: i18n.t('list'), value: 'list_name', width: 220 },
      { text: i18n.t('customer_patient'), value: 'customer_name', width: 220 },
      { text: i18n.t('type'), value: 'course_type', width: 170 },
      { text: i18n.t('date'), value: 'date', width: 170 },
      {
        text: i18n.t('net_amount'),
        value: 'cost',
        width: 200,
        align: 'end',
      },
    ])
    const options = ref({})
    const columns2 = ref([
      { text: i18n.t('staff_team'), value: 'user_fullname', width: 300 },
      {
        text: i18n.t('checkCostDF'),
        value: 'checking',
        width: 300,
        align: 'end',
      },
      {
        text: i18n.t('serviceCostDF'),
        value: 'serving',
        width: 300,
        align: 'end',
      },
      {
        text: i18n.t('total'),
        value: 'total',
        width: 300,
        align: 'end',
      },
    ])
    const dataTableList2 = ref([])
    const exportLoading = ref(false)
    const dataList = ref([])
    const dataDetail = ref([])
    doctorReport.doctorReportList().then(res => {
      doctorList.value = res
      doctorList.value.unshift({
        user_id: '',
        user_fullname: i18n.t('all_doctors'),
      })
    })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        user_id: doctorSelect.value,
        lang: i18n.locale,
      }
      saerchReport(payload.value)
    }

    const saerchReport = async payload => {
      loading.value = true
      const { data, datauser, dataShow, doctorCostList } = await reportDoctor(payload)
      dataList.value = data
      dataTableList2.value = datauser
      dataTableList.value = dataShow
      dataDetail.value = doctorCostList
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('SummaryOfMedicalFees')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)}.xlsx`

      // let { start, end } = this.payload;
      let dataExport = dataList.value.map((item, i) => {
        delete item.order_id_pri
        delete item.id

        return { number: i + 1, ...item }
      })

      let dataExport2 = dataDetail.value.map((item, i) => {
        item.serve_date = sumdatetime(item.serve_date)
        return { number: i + 1, ...item }
      })

      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${i18n.t('SummaryOfMedicalFees')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `],
        [
          '#',
          `${i18n.t('a_number')}`,
          `${i18n.t('receipt_number')}`,
          `${i18n.t('doctor')}`,
          `${i18n.t('list')}`,
          `${i18n.t('customer_patient')}`,
          `${i18n.t('type')}`,
          `${i18n.t('date')}`,
          `${i18n.t('net_amount')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'code',
          'order_id',
          'user_fullname',
          'list_name',
          'customer_name',
          'course_type',
          'date',
          'cost',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }]
      const wscols = [
        { wch: 5 },
        { wch: 12 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 20 },
        { wch: 18 },
        { wch: 20 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('SummaryOfMedicalFees')}`)

      // ============================================================================================================================================================================
      const Heading2 = [
        [`${i18n.t('fotmarListSetCourse')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`],
        [
          '#',
          i18n.t('a_number'),
          i18n.t('date'),
          i18n.t('staff'),
          i18n.t('list'),
          i18n.t('customer_code'),
          i18n.t('customer'),
          i18n.t('tel'),
          i18n.t('drug_code'),
          i18n.t('drug_name'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('fee_cost'),
        ],
      ]
      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(JSON.parse(JSON.stringify(dataExport2))), {
        header: [
          'number',
          'serve_code',
          'serve_date',
          'user_fullname',
          'course_name',
          'customer_id',
          'customer_fullname',
          'customer_tel',
          'servingdetail_drug_id',
          'servingdetail_drug_name',
          'servingdetail_drug_amount',
          'servingdetail_drug_unit',
          'cost_user',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 18 } }]
      const wscols2 = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('fotmarListSetCourse')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      doctorSelect,
      doctorList,
      payload,
      loading,
      dataTableList,
      dataTableList2,
      dataUserList,
      dataShowList,
      columns,
      columns2,
      options,
      exportLoading,
      addPayload,
      saerchReport,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>